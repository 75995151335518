
.slider1 {
    background-image: url('/images/banner2.jpg');
}

.slider2 {
    background-image: url('/images/banner.jpg');    
}

.slider3 {
    background-image: url('/images/banner3.jpg');    
}
.thumbnail {
    text-align: center;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.75);
}